import type { FC } from 'react';
import React, { Fragment, memo, useCallback, useContext, useEffect, useState, useRef } from 'react';
import { css } from '@compiled/react';

import { useQuery } from '@atlassian/ufo-apollo-log/use-query';

import { GeneralShortcutListener, CREATE_COMMENT_SHORTCUT } from '@confluence/shortcuts';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { useRouteActions } from '@confluence/route-manager';
import { DEFAULT_LIMIT } from '@confluence/page-comments-queries';
import {
	VIEW_PAGE_COMMENTS_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import { LoadingPriority } from '@confluence/loadable';
import { CommentPlaceholder } from '@confluence/comment-simple/entry-points/CommentPlaceholder';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { CommentsSectionLoader } from '@confluence/comments-section';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import {
	CommentsSectionQuery,
	CommentsSectionWithoutReactionsQuery,
} from '@confluence/page-comments-queries/entry-points/CommentsSectionQuery.graphql';
import { useObjectSidebar, PanelName } from '@confluence/object-sidebar-api';
import { markErrorAsHandled } from '@confluence/graphql';
import type {
	CommentsSectionQuery as CommentsSectionQueryType,
	CommentsSectionWithoutReactionsQuery as CommentsSectionWithoutReactionsQueryType,
	CommentsSectionQueryVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CommentsSectionQuery';

const pageCommentWrapperStyles = css({
	position: 'relative',
	zIndex: 0,
});

const sendKeyboardShortcutAnalytics = (shortcut: string) => {
	void getAnalyticsWebClient().then((analyticsClient) => {
		analyticsClient.sendUIEvent({
			source: 'viewPage',
			action: 'pressed',
			actionSubject: 'keyboardShortcut',
			actionSubjectId: 'createComment',
			attributes: {
				key: shortcut,
			},
		});
	});
};

const LazyLoadablePageComments = ({
	children,
	isContentReady,
	shouldLazyLoad,
	renderPlaceholder = undefined,
	loadPageCommentsCallback = undefined,
	isCommentsReady,
}: {
	children: React.ReactElement;
	isContentReady: boolean;
	shouldLazyLoad?: boolean;
	renderPlaceholder?: () => React.ReactElement;
	loadPageCommentsCallback?: () => void;
	isCommentsReady: boolean;
}) => {
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const observerRef = useRef<IntersectionObserver | null>(null);

	// load comments immediately if not lazy load, otherwise wait until it is in the viewport
	const [loadPageComments, setLoadPageComments] = useState(!shouldLazyLoad);

	const [{ isObjectSidebarShown, panel }] = useObjectSidebar();

	const isCommentsPanelShown = isObjectSidebarShown && panel?.id === PanelName.CommentsPanel;

	const { setQueryParams } = useRouteActions();

	useEffect(() => {
		// viewport intersection detection for SPA only
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (!process.env.REACT_SSR) {
			// eslint-disable-next-line no-restricted-syntax
			observerRef.current = new IntersectionObserver(([entry]) => {
				if (entry.isIntersecting) {
					// show page comments when the wrapper is in viewport
					setLoadPageComments(true);
					observerRef.current?.disconnect();
				}
			});

			// Remove the observer as soon as the component is unmounted
			return () => {
				observerRef.current?.disconnect();
			};
		}
		// NOOP for SSR
	}, []);

	useEffect(() => {
		// viewport intersection detection for SPA only
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (!process.env.REACT_SSR) {
			if (wrapperRef.current && !loadPageComments && isContentReady) {
				// attach intersection observer after content is ready
				observerRef.current?.observe(wrapperRef.current);
			}
		}
	}, [loadPageComments, isContentReady]);

	useEffect(() => {
		if (loadPageComments) {
			loadPageCommentsCallback?.();
		}
	}, [loadPageComments, loadPageCommentsCallback]);

	// render placeholder only in SSR
	const shouldRenderPageComments =
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		!process.env.REACT_SSR &&
		(loadPageComments ||
			(isCommentsPanelShown && fg('confluence-frontend-comments-panel-design-update')));

	const openCommentEditorShortcutCb = (e: KeyboardEvent, shortcut: string) => {
		e.preventDefault();
		if (document.querySelector('[data-testid="highlightActionsPopup"]')) {
			return;
		}
		if (!isCommentsReady) {
			setQueryParams({
				showCommentArea: 'true',
			});
			//This will scroll to and load the page comments section if its not available (e.g. on long pages)
			wrapperRef.current?.scrollIntoView && wrapperRef.current?.scrollIntoView();
		} else {
			window.dispatchEvent(new Event('openPageCommentEditor'));
		}
		sendKeyboardShortcutAnalytics(shortcut);
	};

	return (
		<div data-testId="page-comment-wrapper" ref={wrapperRef} css={pageCommentWrapperStyles}>
			<GeneralShortcutListener
				accelerator={CREATE_COMMENT_SHORTCUT}
				listener={(e) => openCommentEditorShortcutCb(e, CREATE_COMMENT_SHORTCUT)}
			/>
			{shouldRenderPageComments ? children : renderPlaceholder?.()}
		</div>
	);
};

const ViewPageFooterCommentsComponent: FC<{
	contentId: string;
	loadingPriority: LoadingPriority | null;
	classicComments?: React.ComponentType<any>;
	hasReactions?: boolean;
}> = memo(({ contentId, classicComments, loadingPriority, hasReactions }) => {
	const [state] = useContentState();

	/* Preload CommentsSectionQuery - We preload CommentsSectionQuery as part of next/packages/query-preloaders/src/index.ts,
    but its bestEffort Preloading - meaning if it exceeds the threshold it wont wait for query to finish
    Adding preloading here as well to ensure data is ready once user scrolls*/

	const { error } = useQuery<
		CommentsSectionQueryType | CommentsSectionWithoutReactionsQueryType,
		CommentsSectionQueryVariables
	>(hasReactions ? CommentsSectionQuery : CommentsSectionWithoutReactionsQuery, {
		variables: {
			contentId,
			offset: '',
			first: DEFAULT_LIMIT,
		},
		fetchPolicy: 'cache-and-network',
		skip: !contentId,
	});

	/*  Marking all graphql errors here as handled since we are only preloading query. The CommentsSectionFabric component
    where the query data is used has exhaustive error handling*/

	if (error) {
		markErrorAsHandled(error);
	}

	const { getQueryParams } = useContext(RoutesContext);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { focusedCommentId, replyToComment, showCommentArea, editComment, commentId } =
		getQueryParams();

	/* check for query params - If anyone of these are true just load Page comments */
	const isFocusedComment = Boolean(
		focusedCommentId || replyToComment || showCommentArea || (editComment === 'true' && commentId),
	);

	const [isCommentsReady, setIsCommentsReady] = useState(false);

	const startExperienceTracker = useCallback(() => {
		experienceTracker.start({
			name: VIEW_PAGE_COMMENTS_EXPERIENCE,
			attributes: {
				isConditionalRenderingOn: true,
				isFocusedComment,
				editor: 'FABRIC',
				contentId,
			},
		});
	}, [experienceTracker, isFocusedComment, contentId]);

	/* Display comment Placeholder if isContentReady is true - Waiting for content to render to
	display placholder since at times it will display the placeholder towards middle of screen
	and then get pushed down*/
	const renderCommentPlaceholder = useCallback(() => {
		const showPlaceholder =
			// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
			Boolean(process.env.REACT_SSR) || window.__SSR_RENDERED__ || state.isContentReady;
		return <CommentPlaceholder showPlaceholder={showPlaceholder} />;
	}, [state.isContentReady]);

	return (
		<LazyLoadablePageComments
			shouldLazyLoad={!isFocusedComment}
			isContentReady={state.isContentReady}
			renderPlaceholder={renderCommentPlaceholder}
			loadPageCommentsCallback={startExperienceTracker}
			isCommentsReady={isCommentsReady}
		>
			<Fragment>
				{
					// Important! Here, the renderCommentPlaceholder() must match the renderPlaceholder
					// in LazyLoadPageComments to ensure the initial hydration matches the SSR render result
					!isCommentsReady && renderCommentPlaceholder()
				}
				<CommentsSectionLoader
					contentId={contentId}
					classicComments={classicComments}
					handlePageCommentsRender={() => setIsCommentsReady(true)}
					loadingPriority={loadingPriority || LoadingPriority.PAINT}
				/>
			</Fragment>
		</LazyLoadablePageComments>
	);
});

export const ViewPageFooterComments = withErrorBoundary({
	attribution: Attribution.COLLABORATION,
})(ViewPageFooterCommentsComponent);
