import { useCallback } from 'react';

import uuid from 'uuid/v4';

import { withFlags, type FlagsStateContainer } from '@confluence/flags';
import { usePubSubEvent } from '@confluence/pubsub-client';
import { useSessionData } from '@confluence/session-data';
import { usePageContentId } from '@confluence/page-context';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { FORCE_TRANSITION_QUERY_PARAM } from '@confluence/named-routes';

import { useShowConvertToLivePageFlag } from './useShowConvertToLivePageFlag';

type ConvertToLivePageFlagProps = {
	flags: FlagsStateContainer;
};

export const ConvertToLivePageFlag = withFlags(({ flags }: ConvertToLivePageFlagProps) => {
	const [contentId] = usePageContentId();
	const { userId } = useSessionData();
	const { setQueryParams } = useRouteActions();
	const showConvertToLivePageFlag = useShowConvertToLivePageFlag({ flags });

	const onEvent = useCallback(
		(_: any, payload: any) => {
			const { accountId } = payload;
			showConvertToLivePageFlag({ converterWasCurrentUser: accountId === userId });

			// When another user converts the page to a live doc, we need to add a query param to avoid the double action bar bug.
			// Without this, the page may read stale SSR values indicating it's not a live page, causing UI weirdness.
			// The query param forces RouteState to increment transitionId, preventing it from using initial SSR values.
			setQueryParams({ [FORCE_TRANSITION_QUERY_PARAM]: uuid() });
		},
		[showConvertToLivePageFlag, userId, setQueryParams],
	);

	usePubSubEvent({
		// contentId might be undefined coming from usePageContentId, skipping pubsub event if so
		skip: !contentId,
		// Empty contentId will not happen as pubsub subscription will be skipped
		contentId: contentId ?? '',
		contentType: 'page',
		eventName: 'convertToLiveEdit:page',
		onEvent,
	});

	return null;
});
